import React from 'react';

const ContactCallout = (props) => (
  <div className="contact-callout-container callout-color has-text-centered notification">
    <div className="contact-callout-text is-uppercase">{props.text}</div>
    <div className="contact-callout-phone">{props.phone}</div>
  </div>
);

export default ContactCallout;
