import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/bulma/BulmaLayout';
import ContactCallout from '../components/ContactCallout';
import SectionWrapper from '../components/bulma/BulmaSectionWrapper';
import PageTitle from '../components/PageTitle';
import SectionTitle from '../components/SectionTitle';
import site from '../../data/siteConf';

const page = site.contactPage;

const ContactPage = () => (
  <Layout>
    <Helmet>
      <title>
        {site.siteTitle} - {page.title}
      </title>
      <meta name="description" content={page.metaDescription} />
    </Helmet>
    <SectionWrapper>
      <PageTitle text="Contact Us" />
      <div className="columns">
        <div className="column is-half">
          <ContactCallout text={site.contactCalloutText} phone={site.contact.phone} />
          <div className="contact-address-container">
            <span className="address-company has-text-weight-semibold is-size-5">{site.contact.company}</span>
            <span className="address">{site.contact.address1}</span>
            <span className="address">
              {site.contact.city}, {site.contact.state} {site.contact.zipcode}
            </span>
            <span className="address-phone">{site.contact.phone}</span>
            <span className="address-email">
              <a href={`mailto:${site.contact.email}`}>{site.contact.email}</a>
            </span>
          </div>
        </div>
        <div className="column">
          <div className="contact-us-map">
            <figure className="">
              <img
                src="https://maps.googleapis.com/maps/api/staticmap?center=Frying+Pan+River+Lodge,Basalt,CO+81621&zoom=16&size=600x400&markers=size:large%7Ccolor:red%7CFrying+Pan+River+Lodge,+Basalt,CO+81621&key=AIzaSyBfNA4R0oJy_6HeSIK8wpOLuGTam88CSDA"
                alt="Google map of Basalt, CO"
              />
            </figure>
          </div>
        </div>
      </div>
    </SectionWrapper>
    <SectionWrapper>
      <PageTitle text="Other Lodging Options" />
      <p>
        In addition to the Frying Pan River Lodge, we also own The Aspenalt in downtown Basalt and our pet friendly
        Taylor Creek Cabins near mile marker 5 on Frying Pan River Road.
      </p>
      <div className="columns">
        <div className="column">
          <SectionTitle text="Aspenalt Lodge" />
          <img
            src="https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_300,h_200/fprl/aspenalt-garden-view.png"
            alt="Aspenalt Lodge from the back garden"
          />
        </div>
        <div className="column">
          <SectionTitle text="Taylor Creek Cabins" />
          <img
            src="https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_300,h_200/fprl/taylor-creek-cabins.jpg"
            alt="Taylor Creek Cabins"
          />
        </div>
        <div className="column">
          <div className="affiliation-container">
            <div className="affiliation-text">The following properties are a division of Frying Pan Anglers.</div>
            <div className="affiliation-location">
              <SectionTitle text="Basalt, Colorado" />
              <a href="http://www.fryingpananglers.com">Frying Pan Anglers</a>
              <a href="/">Frying Pan River Lodge</a>
              <a href="http://taylorcreekcabins.com">Taylor Creek Cabins</a>
            </div>
            <div className="affiliation-location">
              <SectionTitle text="Woodland Park, Colorado" />
              <a href="https://southplatteflyshop.com">South Platte Fly Shop</a>
            </div>
            <div className="affiliation-location">
              <SectionTitle text="Durango, Colorado" />
              <a href="http://www.thesanjuanangler.com">The San Juan Angler</a>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  </Layout>
);

export default ContactPage;
